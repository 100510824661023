<template>
  <AuthWrapper>
    <!-- <p class="auth-notice">Don&rsquo;t have an account? <router-link to="/auth/register">Sign up now</router-link></p> -->
    <div class="auth-contents">
      <a-form @finish="handleSubmit" :model="formState" layout="vertical">
        <sdHeading as="h3"> ยินดีต้อนรับ เข้าสู่ระบบ <span class="color-secondary">ครูแคร์</span> </sdHeading>

        <p class="form-divider">
          <span>เพื่อความสะดวกในการใช้งาน กรุณา</span>
        </p>

        <div class="auth0-login">
          <a class="line-login" href="#" @click="() => lineLogin()">
            <img class="" :src="require('@/static/img/line.png')" alt="" />
            <span>เข้าสู่ระบบด้วย LINE</span>
          </a>
        </div>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons-vue';
import { computed, reactive, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { AuthWrapper } from './style';
import { useRouter } from 'vue-router';
import { Auth0Lock } from 'auth0-lock';
import { auth0options } from '@/configs/auth0';

const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;

const SignIn = {
  name: 'SignIn',
  components: { FacebookOutlined, TwitterOutlined, AuthWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const isLoading = computed(() => state.firebase.loading);
    const uid = computed(() => state.firebase.uid);
    const checked = ref(null);
    const router = useRouter();

    const handleSubmit = value => {
      dispatch('firebaseAuthLogin', { data: value });
    };

    const handleFbLogin = () => {
      router.push('/');
      dispatch('login');
    };

    watchEffect(() => {
      if (uid.value) {
        handleFbLogin();
      }
    });

    const onChange = checked => {
      checked.value = checked;
    };

    const formState = reactive({
      username: '',
      password: '',
    });

    const lock = new Auth0Lock(clientId, domain, auth0options);

    lock.on('authenticated', authResult => {
      lock.getUserInfo(authResult.accessToken, error => {
        if (error) {
          return;
        }

        handleSubmit();
        lock.hide();
      });
    });

    const lineLogin = () => {
      const stateCode = Math.random()
        .toString(36)
        .substring(2, 7);

      const nonce = Math.random()
        .toString(36)
        .substring(2, 7);

      const clientId = process.env.VUE_APP_LINE_CHANNEL_ID;
      const redirectUrl = encodeURIComponent(`${process.env.VUE_APP_BASE_URL}auth/line-callback`);
      const url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&state=${stateCode}&scope=profile%20openid&nonce=${nonce}`;

      window.location = url;
    };

    return {
      isLoading,
      checked,
      handleSubmit,
      lineLogin,
      onChange,
      formState,
      dispatch,
    };
  },
};

export default SignIn;
</script>
